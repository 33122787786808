<template>
  <div>
    <b-card>
      <div class="w-100 text-left">
        <b-button
          @click="saveExcelDoc()"
          variant="primary"
        >
          {{ $t('saveAsExcel') }}
        </b-button>
      </div>
      <Table
        :loader="tableLoader"
        :items="items.results"
        :fields="fields"
        :sortBy="sortAcs"
        :sortAcs="sortAcs"
        :sortDesc="sortDesc"
        :getDocuments="getReport"
        :total="total"
        type="reportDistributionSailor"/>
      <Paginate
        :current="items.current"
        :next="items.next"
        :prev="items.previous"
        :count="items.count"
        :changePage="getReport" />
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { queryString } from '@/mixins/main'

export default {
  name: 'ReportDistributionAgent',
  components: {
    Table: () => import('@/components/layouts/Table/Table.vue'),
    Paginate: () => import('@/components/atoms/Paginate.vue')
  },
  props: {
    search: String
  },
  data () {
    return {
      fields: [
        { key: 'sailorFullName',
          label: this.$i18n.t('sailor')
        },
        { key: 'distribution_sum',
          label: this.$i18n.t('distribution')
        },
        { key: 'profit_sum',
          label: this.$i18n.t('profit')
        },
        { key: 'event',
          label: this.$i18n.t('go'),
          class: 'mw-0'
        }
      ],
      items: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      url: null,
      typeDoc: this.$router.currentRoute.params['typeDoc'],
      firstIdEntry: this.$router.currentRoute.params['firstIdEntry'],
      secondIdEntry: this.$router.currentRoute.params['secondIdEntry'],
      total: {}
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  mounted () {
    this.getReport()
  },
  methods: {
    getReport (link = null) {
      this.tableLoader = true
      if (link) {
        this.url = link
      } else {
        switch (this.typeDoc) {
          case 'sqc':
          case 'crewing_manager':
            this.url = `api/v1/reports/back_office/distribution/${this.typeDoc}/sailor/?manager=${this.secondIdEntry}&${this.search}`
            this.fields.splice(1, 0, { key: 'rank', label: this.$i18n.t('rank') })
            break
          case 'portal':
          case 'sc':
            this.url = `api/v1/reports/back_office/distribution/${this.typeDoc}/sailor/?branch_office=${this.firstIdEntry}&manager=${this.secondIdEntry}&${this.search}`
            this.fields.splice(1, 0, { key: 'rank', label: this.$i18n.t('rank') })
            break
          case 'eti':
            this.url = `api/v1/reports/back_office/distribution/${this.typeDoc}/sailor/?institution=${this.firstIdEntry}&course=${this.secondIdEntry}&${this.search}`
            break
          case 'medical':
            this.url = `api/v1/reports/back_office/distribution/${this.typeDoc}/sailor/?medical=${this.firstIdEntry}&doctor=${this.secondIdEntry || 0}&${this.search}`
            this.fields.unshift({ key: 'number_document', label: this.$i18n.t('number') })
            this.fields.splice(2, 0, { key: 'position', label: this.$i18n.t('position') })
            break
          case 'dpd':
            const query = queryString({
              ...this.$route.query,
              manager: this.secondIdEntry
            })
            this.url = `api/v1/reports/back_office/distribution/${this.typeDoc}/${this.firstIdEntry === 'passport' ? 'sailor_passport' : 'qual_doc'}/${query}`
            if (this.secondIdEntry === 'passport') {
              this.fields.unshift({ key: 'number_document', label: this.$i18n.t('number') })
            } else {
              this.fields.splice(1, 0, { key: 'rank', label: this.$i18n.t('rank') })
              this.fields.splice(2, 0, { key: 'list_positions', label: this.$i18n.t('position') })
            }
            break
          case 'adv_training':
            this.url = `api/v1/reports/back_office/distribution/${this.typeDoc}/sailor/?level=${this.secondIdEntry}&${this.$route.query.search}`
        }
      }

      this.$api.get(this.url).then(response => {
        let cloneResponce = { ...response.data.results }
        delete cloneResponce.data
        this.total = cloneResponce
        this.total.columns = 1
        this.total.type = 'DistributionSailor'
        this.tableLoader = false
        if (response.code === 200) {
          if (this.secondIdEntry === 'qualification') {
            response.data.results.data.map(item => {
              item._list_positions = item.list_positions.map(position => position[this.labelName])
            })
          }
          this.items = response.data
          this.items.results = response.data.results.data
        }
      })
    },

    /** Generate excel document */
    saveExcelDoc () {
      const excelUrl = this.url.split('?').join('xlsx/?')
      this.$api.get(excelUrl).then(() => {
        this.$notification.success('excelStatement')
      })
    }
  }
}
</script>
